import React from 'react';
export default function PartyPopperIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg width="45" height="45" viewBox="0 0 45 45" {...props} fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="PartyPopperIcon" data-sentry-source-file="partyPopperIcon.tsx">
      <g clipPath="url(#clip0_10265_168)" data-sentry-element="g" data-sentry-source-file="partyPopperIcon.tsx">
      <path d="M0.920077 44.7219C1.78328 45.6397 5.34932 44.0405 8.16536 42.7472C10.2739 41.7877 18.722 38.3807 22.9106 36.6147C24.0427 36.1419 25.6984 35.5161 26.8729 33.9864C27.9201 32.6236 30.722 26.8248 25.0899 20.9564C19.3871 14.9907 13.5003 16.6456 11.3069 18.1752C10.0191 19.0791 8.95781 21.1094 8.49083 22.1385C6.49555 26.4911 3.63706 34.4731 2.47668 37.6159C1.62762 39.9243 0.0427182 43.8319 0.905926 44.7358L0.920077 44.7219Z" fill="#FFC107" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M7.93809 23.4318C7.99469 24.0853 8.12205 25.1422 8.58903 27.1864C8.90035 28.5909 9.40979 30.065 9.83432 31.0245C11.0796 33.9447 12.8343 35.1268 14.6032 36.0724C17.6173 37.6716 19.6692 37.9775 19.6692 37.9775L17.207 38.9648C17.207 38.9648 15.7211 38.6589 13.6834 37.6855C11.7447 36.7538 9.73526 35.1685 8.26356 32.0535C7.62677 30.7046 7.25884 29.3975 7.04658 28.3684C6.77771 27.0891 6.73526 26.352 6.73526 26.352L7.93809 23.4318ZM4.92394 31.0384C4.92394 31.0384 5.23526 33.4719 7.27299 36.5452C9.66451 40.1329 13.0183 40.7309 13.0183 40.7309L10.7966 41.6348C10.7966 41.6348 8.30601 40.8839 5.95696 37.7133C4.48526 35.7386 4.07488 33.3746 4.07488 33.3746L4.93809 31.0384H4.92394ZM2.8013 36.7955C2.8013 36.7955 3.35318 38.8953 4.58432 40.4667C6.04186 42.344 7.89564 42.8863 7.89564 42.8863L6.18337 43.6234C6.18337 43.6234 4.89564 43.3591 3.48054 41.5931C2.40507 40.2581 2.09375 38.7145 2.09375 38.7145L2.8013 36.7955Z" fill="#FF8F00" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <g opacity="0.44" data-sentry-element="g" data-sentry-source-file="partyPopperIcon.tsx">
      <path d="M1.88119 42.1353C1.81044 41.9685 1.81044 41.7738 1.88119 41.6069L11.6029 21.8047L13.2019 27.7148L2.97081 42.2327C2.70195 42.636 2.0793 42.5803 1.88119 42.1492V42.1353Z" fill="#FFFDE7" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      </g>
      <mask id="mask0_10265_168" style={{
        maskType: 'luminance'
      }} maskUnits="userSpaceOnUse" x="11" y="18" width="17" height="16" data-sentry-element="mask" data-sentry-source-file="partyPopperIcon.tsx">
      <path d="M13.9658 29.6895C18.5083 34.9042 23.6733 34.2506 25.3998 32.9435C27.1262 31.6224 28.4847 27.0751 23.9705 21.9438C19.23 16.5621 13.8667 18.1057 12.6215 19.2599C11.3762 20.428 9.80542 24.9058 13.9658 29.7034V29.6895Z" fill="white" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      </mask>
      <g mask="url(#mask0_10265_168)" data-sentry-element="g" data-sentry-source-file="partyPopperIcon.tsx">
      <path d="M28.4822 25.615L22.9492 16.5621H28.4822V25.615Z" fill="#8F4700" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4835 26.1712L22.625 16.5621H22.9505L28.4835 25.615V26.1712Z" fill="#8F4700" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4833 26.7136L22.2852 16.5621H22.6248L28.4833 26.1712V26.7136Z" fill="#8E4701" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.483 27.2559L21.9453 16.5621H22.2849L28.483 26.7136V27.2559Z" fill="#8E4702" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4843 27.8121L21.6211 16.5621H21.9466L28.4843 27.2559V27.8121Z" fill="#8D4603" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4841 28.3545L21.2812 16.5621H21.6209L28.4841 27.8121V28.3545Z" fill="#8C4604" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4839 28.9107L20.9414 16.5621H21.281L28.4839 28.3545V28.9107Z" fill="#8B4605" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4851 29.453L20.6172 16.5621H20.9427L28.4851 28.9107V29.453Z" fill="#8B4606" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4849 30.0093L20.2773 16.5621H20.617L28.4849 29.4531V30.0093Z" fill="#8A4607" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4847 30.5516L19.9375 16.5621H20.2771L28.4847 30.0093V30.5516Z" fill="#894508" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.482 31.1079L19.6094 16.5621H19.9348L28.482 30.5516V31.1079Z" fill="#894509" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4857 31.6502L19.2734 16.5621H19.6131L28.4857 31.1079V31.6502Z" fill="#88450A" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4855 32.2064L18.9336 16.5621H19.2732L28.4855 31.6502V32.2064Z" fill="#87450B" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.4853 32.3038L28.2871 32.415L18.5938 16.5621H18.9334L28.4853 32.2064V32.3038Z" fill="#87450C" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.2845 32.415L28.0298 32.568L18.2656 16.5621H18.5911L28.2845 32.415Z" fill="#86440D" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M28.0296 32.568L27.789 32.7071L17.9258 16.5621H18.2654L28.0296 32.568Z" fill="#85440E" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M27.7888 32.7071L27.5482 32.86L17.5859 16.5621H17.9256L27.7888 32.7071Z" fill="#84440F" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M27.5495 32.86L27.2947 32.9991L17.2617 16.5621H17.5872L27.5495 32.86Z" fill="#844410" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M27.2945 32.9991L27.054 33.1382L16.9219 16.5621H17.2615L27.2945 32.9991Z" fill="#834411" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M27.0537 33.1382L26.799 33.2911L16.582 16.5621H16.9217L27.0537 33.1382Z" fill="#824312" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M26.8003 33.2911L26.5597 33.4302L16.2578 16.5621H16.5833L26.8003 33.2911Z" fill="#824313" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M26.5595 33.4302L26.3189 33.5831L15.918 16.5621H16.2576L26.5595 33.4302Z" fill="#814314" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M26.3187 33.5831L26.064 33.7222L15.5781 16.5621H15.9177L26.3187 33.5831Z" fill="#804315" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M26.0652 33.7222L25.8247 33.8752L15.2539 16.5621H15.5794L26.0652 33.7222Z" fill="#804317" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M25.822 33.8752L25.5673 34.0142L14.9258 16.5899L14.9824 16.5621H15.2513L25.822 33.8752Z" fill="#7F4218" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M14.9224 16.5867L14.6797 16.7297L25.3197 34.1656L25.5624 34.0226L14.9224 16.5867Z" fill="#7E4219" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M14.6763 16.7355L14.4336 16.8787L25.0798 34.3109L25.3224 34.1678L14.6763 16.7355Z" fill="#7D421A" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M14.438 16.8717L14.1953 17.0148L24.8353 34.4507L25.078 34.3076L14.438 16.8717Z" fill="#7D421B" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M14.1919 17.0224L13.9492 17.1654L24.5892 34.6013L24.8319 34.4583L14.1919 17.0224Z" fill="#7C411C" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M13.9418 17.1747L13.6992 17.3179L24.3516 34.7465L24.5942 34.6033L13.9418 17.1747Z" fill="#7B411D" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M13.6958 17.3146L13.4531 17.4576L24.0931 34.8935L24.3358 34.7505L13.6958 17.3146Z" fill="#7B411E" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M24.0954 34.8903L24.053 34.9182H23.77L13.1992 17.6051L13.4539 17.4521L24.0954 34.8903Z" fill="#7A411F" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M23.4468 34.9181L12.9609 17.7442L13.2015 17.6051L23.7723 34.9181H23.4468Z" fill="#794120" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M23.1055 34.9181L12.7188 17.8971L12.9593 17.7441L23.4452 34.9181H23.1055Z" fill="#784021" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M22.7667 34.9181L12.4648 18.0362L12.7196 17.8971L23.1064 34.9181H22.7667Z" fill="#784022" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M22.4396 34.9181L12.2227 18.1891L12.4632 18.0361L22.7651 34.9181H22.4396Z" fill="#774023" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M22.1008 34.9181L11.9688 18.3281L12.2235 18.1891L22.4404 34.9181H22.1008Z" fill="#764024" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M21.7635 34.9182L11.7305 18.4673L11.971 18.3282L22.1031 34.9182H21.7635Z" fill="#764025" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M21.4364 34.9181L11.4883 18.6203L11.7288 18.4673L21.7619 34.9181H21.4364Z" fill="#753F26" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M21.0976 34.9181L11.2344 18.7593L11.4891 18.6202L21.4372 34.9181H21.0976Z" fill="#743F27" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M20.7563 34.9181L10.9922 18.9122L11.2328 18.7593L21.096 34.9181H20.7563Z" fill="#743F28" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M20.4175 34.9181L10.7383 19.0513L10.993 18.9122L20.7571 34.9181H20.4175Z" fill="#733F29" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M20.0943 34.9181L10.5 19.2042L10.7406 19.0513L20.4198 34.9181H20.0943Z" fill="#723F2A" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M19.7531 34.9182L10.2578 19.3434L10.4984 19.2043L20.0927 34.9182H19.7531Z" fill="#713E2B" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M19.4143 34.9182L10.0039 19.4964L10.2586 19.3434L19.7539 34.9182H19.4143Z" fill="#713E2C" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M19.0877 34.9182L9.80469 19.7049V19.6076L10.0028 19.4963L19.4132 34.9182H19.0877Z" fill="#703E2D" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M9.80469 19.705L19.0877 34.9182H9.80469V19.705Z" fill="#703E2D" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      </g>
      <path d="M29.5627 31.8449C27.9071 30.4821 27.0297 30.7185 25.841 31.2052C24.3127 31.8309 21.9212 32.2898 18.6523 31.2052L19.6288 28.8829C21.5674 29.5225 22.9684 29.2166 24.1712 28.5074C25.7278 27.6035 27.8505 26.3798 31.1618 29.1054C32.5486 30.2457 33.9637 30.9966 34.9967 30.6628C35.7467 30.4125 36.1571 29.3139 36.3552 28.424C36.3693 28.3405 36.4118 28.118 36.4259 27.9233C36.6099 26.5466 36.9212 23.5707 39.1712 22.055C41.5769 20.428 44.1099 20.428 44.1099 20.428L44.5627 24.8918C43.4023 24.7249 42.5957 24.9614 41.9023 25.3229C39.3269 26.7274 41.5769 32.123 37.5722 33.9447C33.7231 35.6968 30.5674 32.6653 29.5486 31.817L29.5627 31.8449Z" fill="#03A9F4" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M15.3955 26.1434L13.7399 24.6832C16.7823 21.3458 15.9757 18.8983 15.3955 17.1044C15.2823 16.7429 15.1691 16.4091 15.0984 16.0754C14.8437 14.9212 14.787 13.92 14.872 13.0439C13.6974 11.6116 13.188 10.1236 13.1597 10.0124C12.4521 7.89864 12.9899 5.84054 14.2069 3.9076C16.6691 0 21.155 0 21.155 0L22.655 3.93541C21.5229 3.8937 17.787 3.93541 16.6408 5.71539C15.1974 7.95426 16.1455 9.33096 16.2163 9.48393C16.4993 9.12237 16.7823 8.84425 17.0229 8.62175C18.8484 7.02256 20.4333 6.80006 21.4521 6.8835C22.5842 6.98084 23.6172 7.55099 24.3531 8.46879C25.1597 9.48393 25.4852 10.7911 25.2304 11.987C24.9757 13.1551 24.1974 14.1425 23.0087 14.7682C20.9427 15.8668 19.2163 15.7138 18.0559 15.3384C18.0559 15.3662 18.0559 15.394 18.0701 15.4218C18.1125 15.6026 18.1974 15.8668 18.2965 16.1727C18.9757 18.2308 20.221 21.4849 15.3955 26.1295V26.1434ZM18.1974 12.0705C18.4238 12.2234 18.6503 12.3625 18.8908 12.4598C19.6974 12.7797 20.5606 12.6684 21.5512 12.14C22.1314 11.8341 22.2021 11.5003 22.2304 11.3891C22.3012 11.0692 22.188 10.652 21.9333 10.3322C21.721 10.0541 21.4663 9.91502 21.1691 9.88721C20.6031 9.84549 19.8248 10.1931 19.0465 10.8745C18.6786 11.1944 18.3955 11.5977 18.1974 12.0705Z" fill="#F44336" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M22.0312 26.7553L19.668 26.6857C19.668 26.6857 20.8 20.4419 24.4368 19.3851C25.1161 19.1904 25.8661 18.9957 26.6161 18.8844C27.0689 18.8149 27.7623 18.7176 28.1161 18.5924C28.201 18.0084 27.9463 17.2574 27.6633 16.4092C27.4368 15.7556 27.2104 15.0742 27.0972 14.3233C26.8566 12.877 27.2529 11.5977 28.201 10.7216C29.3614 9.65083 31.2293 9.31708 33.3519 9.78989C34.5548 10.0541 35.4463 10.6382 36.2246 11.1527C37.3425 11.8897 37.9934 12.2513 39.3661 11.3474C41.0217 10.2488 38.8566 5.96572 37.7104 3.49044L41.9982 1.73828C42.5784 2.97592 45.3519 9.34489 43.5265 12.9744C42.9038 14.1981 41.8425 15.0047 40.4416 15.3106C37.3991 15.9781 35.6302 14.81 34.3283 13.9617C33.7199 13.5584 33.1821 13.2525 32.5878 13.0856C28.5265 11.9453 34.201 17.8137 31.5406 20.4558C29.9557 22.0411 26.0642 22.4444 25.8095 22.5139C23.3048 23.1119 22.0453 26.7692 22.0453 26.7692L22.0312 26.7553Z" fill="#F48FB1" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M14.8573 13.0438C14.7865 13.8643 14.744 14.3649 14.9705 15.4357C16.0176 16.1866 18.3101 16.1866 18.3101 16.1866C18.211 15.8807 18.1261 15.6164 18.0837 15.4357C18.0837 15.4079 18.0837 15.38 18.0695 15.3522C15.7488 14.2119 14.8714 13.0438 14.8714 13.0438H14.8573Z" fill="#C92B27" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M10.1044 16.7428L6.15625 14.8377L8.12323 12.0426L11.2223 14.059L10.1044 16.7428Z" fill="#FFC107" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M4.28774 11.4725C2.2783 11.2083 0.226415 9.52569 0 9.33101L1.98113 7.05042C2.57547 7.55103 3.84906 8.3854 4.69811 8.49664L4.28774 11.4725Z" fill="#FB8C00" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M7.85407 6.48016L4.95312 5.54846C5.2786 4.54722 5.37765 3.47645 5.20784 2.4335L8.22199 1.96069C8.47671 3.46255 8.34935 5.03393 7.85407 6.48016Z" fill="#03A9F4" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M25.9531 4.24136L28.939 3.60168L29.8022 7.46757L26.8163 8.10725L25.9531 4.24136Z" fill="#FB8C00" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M33.3522 5.15909L31.2578 2.97584C32.3616 1.94679 32.6022 0.611806 32.6022 0.5979L35.6163 1.08461C35.5739 1.32102 35.1918 3.44864 33.3522 5.15909Z" fill="#FFC107" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M34.5156 16.7151L37.176 15.8947L38.0817 18.7593L35.4213 19.5798L34.5156 16.7151Z" fill="#FB8C00" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M35.2909 40.8838L32.2626 40.5361C32.39 39.4793 31.5834 38.1721 31.3711 37.8801L33.8192 36.0862C34.0032 36.3226 35.5881 38.4919 35.3051 40.8838H35.2909Z" fill="#F44336" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M44.0114 37.0736C42.8651 36.9067 41.7047 36.8372 40.5444 36.8789L40.4453 33.8752C41.7897 33.8335 43.134 33.903 44.4642 34.0977L44.0114 37.0597V37.0736Z" fill="#FB8C00" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M39.9062 41.2037L42.0572 39.076L45.0147 41.9407L42.8638 44.0683L39.9062 41.2037Z" fill="#F48FB1" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      <path d="M33.5931 22.2496L35.8006 24.7249L33.2818 26.8943L31.0742 24.419L33.5931 22.2496Z" fill="#F44336" data-sentry-element="path" data-sentry-source-file="partyPopperIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="partyPopperIcon.tsx">
      <clipPath id="clip0_10265_168" data-sentry-element="clipPath" data-sentry-source-file="partyPopperIcon.tsx">
      <rect width="45" height="45" fill="white" data-sentry-element="rect" data-sentry-source-file="partyPopperIcon.tsx" />
      </clipPath>
      </defs>
    </svg>;
}